
    import { EventApiClient, PublicationApiClient, PublicationHomeModel, EventModel, TranslationsApiClient, EventCategoryModel, PublicationMainCategoryModel } from '../../generated';
    import { defineComponent } from 'vue';
    import { store } from '../../store/store';
    import moment from 'moment';
    import { LanguageMixin } from '@/mixins/LanguageMixin';

    import {
        IonList,
        IonCard,
        IonImg,
    } from '@ionic/vue';
    import { SharedMixin } from '../../mixins/SharedMixin';

    export default defineComponent({
        name: 'Home',
        mixins: [SharedMixin, LanguageMixin],
        components: {
            IonList,
            IonCard,
            IonImg,
        },
        data() {
            return {
                publications: Array<PublicationHomeModel>(),
                events: Array<EventCategoryModel>(),
                publicationsMainCategories: Array<PublicationMainCategoryModel>(),
                textHome: ""
            }
        },
        methods: {
            Published: function (e: PublicationHomeModel) {
                return '';
            },
            FormateEventDates: function (e: EventModel) {
                const formatDateFrom = moment(e.timeFrom).locale(store.state.currentLanguage != null ? store.state.currentLanguage.split('-')[0] : ''),
                    formatDateUntil = moment(e.timeUntil).locale(store.state.currentLanguage != null ? store.state.currentLanguage.split('-')[0] : '');

                const formatFromLocal = formatDateFrom.format('D MMM YYYY'),
                    formatUntilLocal = formatDateUntil.format('D MMM YYYY');

                if (formatFromLocal === formatUntilLocal) {
                    return formatFromLocal;
                } else {
                    return formatFromLocal + ' t/m ' + formatUntilLocal;
                }
            },
            loadDataEvents: function () {
                const eventsClient = new EventApiClient();
                eventsClient.getHomeEvents(store.state.currentLanguage).then(result => {
                    if (result != null) {
                        this.events = result;
                    }
                });

                //const translationsApiClient = new TranslationsApiClient();
                //translationsApiClient.getTranslations("nl-NL").then(result => {
                //    console.log(result);
                //    if (result != null) {
                //        // this.events = result;
                //    }
                //});
            },
            loadDataPublications: function () {
                const publicationsClient = new PublicationApiClient();
                publicationsClient.getHomePublications(store.state.currentLanguage).then(result => {
                    if (result != null) {
                        console.log(result);
                        this.publications = result;
                    }
                });

                //publicationsClient.getPublicationMainCategory(store.state.currentLanguage).then(result => {
                //    if (result != null) {
                //        console.log(result);
                //        this.publicationsMainCategories = result;
                //    }
                //});
            }
        },
        ionViewDidEnter() {
            this.loadDataEvents();
            this.loadDataPublications();
        },

        //setup() {
        //    //const publications = ref(Array<PublicationModel>());
        //    //const events = ref(Array<EventModel>());


        //    onMounted(() => {

        //        const eventClient = new EventApiClient();
        //        eventClient.getAll(store.state.currentLanguage).then(result => {
        //            console.log(result);
        //            if (result != null) {
        //                events.value = result;
        //                console.log(events);
        //            }
        //        });

        //        //const client = new PublicationApiClient();
        //        //client.getAll().then(result => {
        //        //    console.log(result);
        //        //    if (result != null) {
        //        //        publications.value = result;
        //        //    }
        //        //});
        //    });

        //    return {
        //        events,
        //        publications
        //    }
        //}
    })
